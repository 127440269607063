@import './scss/material.variables';
@import './scss/material.themes';
@import './scss/mixins/bi-app/bi-app-ltr';
// Core Variables and Mixins
@import './scss/app.variables';
@import './scss/mixins';
// Core Styles
@import './scss/core';
@import './scss/material';
@import './scss/utilities/utilities';

@import url(https://fonts.googleapis.com/css?family=Dosis:200,300,400,500,600,700,800&subset=latin-ext);

@import './scss/mapa-tooltip';
