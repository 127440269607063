/* $listgroup
 ------------------------------------------*/
@use '@angular/material' as mat;
mat-list, mat-nav-list {
  mat-list-item, a[mat-list-item] {
    color: rgba(mat.get-color-from-palette($foreground, base), 0.87);
  }
}
mat-list-item,
.mat-list-item {
  mat-icon {
    &[mat-list-avatar] {
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      display: flex;
    }
  }
}
mat-divider {
  border-top: 1px solid mat.get-color-from-palette($foreground, divider) !important;
}
mat-list mat-list-item [mat-list-avatar],
mat-list a[mat-list-item] [mat-list-avatar],
mat-nav-list mat-list-item [mat-list-avatar],
mat-nav-list a[mat-list-item] [mat-list-avatar] {
  min-width: 40px;
}
mat-list-item.active > .mdc-list-item__content {
  background: mat.get-color-from-palette($background, 'hover');
}
mat-list mat-list-item .mdc-list-item__content,
mat-list a[mat-list-item] .mdc-list-item__content,
mat-nav-list mat-list-item .mdc-list-item__content,
mat-nav-list a[mat-list-item] .mdc-list-item__content {
  .mdc-list-item__primary-text{
    font-size: $font-size-base !important;
    text-wrap: wrap;
  }

}
