.radius-none {
  border-radius: 0;
}
.radius-round {
  border-radius: $border-radius-base;
}
.radius-circle {
  border-radius: 50%;
}
.overflow-visible {
  overflow: visible;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.block {
  display: block;
}
.button-block {
  display: block;
  width: 100%;
}
.inline-block {
  display: inline-block;
}
.center-block {
  display: block;
  margin: 0 auto;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.shadow-none {
  box-shadow: none !important;
}
.border-none {
  border: 0 !important;
}
.background-none {
  background-color: transparent !important;
}
.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.custom-expansion-panel {
  padding-left: 0px !important;
  border-left: 7px rgb(173, 162, 190) solid;
  margin-bottom: 0px !important;
  //   border-left: 7px rgb(206, 180, 180) solid;
}

.desactivado {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.oculto {
  display: none;
}

$color: rgb(255, 137, 137);
$color-background: rgb(255, 233, 231);
.error {
  border-top: 1px $color solid !important;
  border-bottom: 1px $color solid !important;
  border-right: 1px $color solid !important;
  background-color: $color-background !important;
}
.errorCheck {
  color: $color !important;
  font-weight: bold !important;
}

.borderError {
  border: 1px solid $color !important;
}

.errorInput {
  color: red;
  font-size: 20px;
  font-weight: bold;
}
